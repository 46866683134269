import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import App from "./App";
import { AiImageProvider } from "./AiImageContext";
import "./styles.css";
import { Loader } from "@react-three/drei";

const client = new ApolloClient({
  uri: "https://buy.absolute-scenes.com/api/2023-04/graphql.json",
  headers: {
    "X-Shopify-Storefront-Access-Token": "a308763406e1a64675e4e16c43eba91e"
  },
  cache: new InMemoryCache()
});

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <ApolloProvider client={client}>
        <AiImageProvider>
          <App />
        </AiImageProvider>
      </ApolloProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
