import * as THREE from "three";
import { useEffect, useLayoutEffect, Suspense, useState, useRef } from "react";
import {
  Canvas,
  applyProps,
  useFrame,
  AnimationMixer
} from "@react-three/fiber";
import {
  useVideoTexture,
  Box,
  useTexture,
  Sphere,
  Cylinder,
  Center,
  AccumulativeShadows,
  RandomizedLight,
  OrbitControls,
  Environment,
  useGLTF,
  TorusKnot,
  Image,
  Float,
  Html,
  Loader,
  ContactShadows,
  Decal,
  Mask,
  useMask,
  Text
} from "@react-three/drei";
import { FlakesTexture } from "three-stdlib";
import { VRButton, XR, Controllers } from "@react-three/xr";
import { folder, useControls, LevaPanel, Leva, button } from "leva";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Scale } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { IconButton, Button, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useAiImageContext } from "./AiImageContext";
import ClipLoader from "react-spinners/ClipLoader";
import { blue } from "@mui/material/colors";

export default function Scene2() {
  const { aiGeneratedImageURL, setAiGeneratedImageURL } = useAiImageContext();

  const [formState, setFormState] = useState({
    Background: "#000000"
  });

  const BackgroundRef = useRef(formState.Background);

  const jwtToken = localStorage.getItem("jwtToken");
  useEffect(() => {
    if (jwtToken) {
      
    }
  }, [jwtToken]);
  const { id } = useParams();

  const [img, setImg] = useState({
    fileURL2: "https://spestect.sirv.com/img-FPV8eXIW9ESuF89esu67NxLA.jpg"
  });

  const [loading, setLoading] = useState(false);

  const generateImage = async (prompt) => {
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json"
      }
    };

    setLoading(true);
    console.log(loading); // Set loading to true at the start of the API call

    try {
      const response = await axios.post(
        "https://api.absolute-scenes.com/generate/image",
        { prompt: prompt },
        config
      );

      if (response.data.url) {
        setImg({
          fileURL2: response.data.url
            ? response.data.url
            : "https://spestect.sirv.com/img-FPV8eXIW9ESuF89esu67NxLA.jpg"
        });

        setAiGeneratedImageURL(response.data.url); // Update the aiGeneratedImageURL directly
      } else {
        console.log("Error generating image:", response);
      }
    } catch (error) {
      console.log("Error generating image:", error.message);
    } finally {
      setLoading(false);
      console.log(loading); // Set loading to false at the end of the API call
    }
  };
  useEffect(() => {
    console.log("Loading state changed:", loading);
  }, [loading]);

  const TcolorRef = useRef(null);
  const [{ Tcolor }, setTcolor] = useControls(() => ({
    Color: folder({
      Tcolor: {
        value: "white",
        label: "T-Shirt Color",
        onChange: (v) => {
          // imperatively update the world after Leva input changes
          TcolorRef.value = v;
        },
        transient: false
      }
    })
  }));

  const [model, setModel] = useState({
    fileURL: "https://spestect.sirv.com/Torus.glb"
  });

  const promptInputRef = useRef(null);

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "0%",
          left: "0%",
          padding: "20px",
          zIndex: "1"
        }}
      >
        <a
          href="https://absolute-scenes.com/home"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/absolutescenes.svg"
            alt="Logo"
            style={{ width: "150px" }}
          />
        </a>
      </div>

      {loading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            marginBottom: "20px",
            zIndex: "1"
          }}
        >
          <ClipLoader color="#ffffff" />
        </div>
      )}
      <div
        style={{
          position: "absolute",
          top: "80px",
          left: "50%",
          transform: "translate(-50%, -50%)",
          marginBottom: "20px",
          zIndex: "1"
        }}
      >
        <input
          type="text"
          placeholder="e.g. Cute Corgi Portrait"
          style={{
            padding: "10px",
            fontSize: "16px",
            borderRadius: "40px",
            border: "1px solid #ccc",
            backgroundColor: "#fff",
            color: "#333",
            paddingRight: "50px",
            width: "300px"
          }}
          ref={promptInputRef}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              generateImage(promptInputRef.current.value);
            }
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "0%",
            right: "3%",
            padding: "10px",
            fontSize: "16px",
            border: "none",
            background: "none",
            color: "#333",
            cursor: "pointer"
          }}
          onClick={() => generateImage(promptInputRef.current.value)}
        >
          <img
            src="/send.svg"
            alt="Submit"
            style={{ width: "20px", height: "20px" }}
          />
        </button>
      </div>

      <Leva hidden />

      <Canvas
        class="canvas"
        shadows
        camera={{ position: [0, 1.5, 10], fov: 25 }}
        style={{ background: BackgroundRef.current }}
      >
        <XR>
          <Controllers />
          <group scale={1} position={[0, 0, 0]}></group>

          <Sphere
            castShadow
            receiveShadow
            args={[0.05, 50, 50]}
            position={[1.5, 0.05, 0]}
          >
            <meshStandardMaterial
              color={BackgroundRef.current}
              roughness={0.5}
            />
          </Sphere>
          <Sphere
            castShadow
            receiveShadow
            args={[0.07, 50, 50]}
            position={[1.3, 0.7, 0.3]}
          >
            <meshStandardMaterial
              color={BackgroundRef.current}
              roughness={0.5}
            />
          </Sphere>
          <Sphere
            castShadow
            receiveShadow
            args={[0.05, 50, 50]}
            position={[1.4, 0.5, -0.3]}
          >
            <meshStandardMaterial
              color={BackgroundRef.current}
              roughness={0.5}
            />
          </Sphere>
          <Sphere
            castShadow
            receiveShadow
            args={[0.07, 50, 50]}
            position={[-1.5, 0.07, 0]}
          >
            <meshStandardMaterial
              color={BackgroundRef.current}
              roughness={0.5}
            />
          </Sphere>
          <Sphere
            castShadow
            receiveShadow
            args={[0.03, 50, 50]}
            position={[-1.2, 0.7, 0.3]}
          >
            <meshStandardMaterial
              color={BackgroundRef.current}
              roughness={0.5}
            />
          </Sphere>
          <Sphere
            castShadow
            receiveShadow
            args={[0.05, 50, 50]}
            position={[-1.4, 0.5, -0.3]}
          >
            <meshStandardMaterial
              color={BackgroundRef.current}
              roughness={0.5}
            />
          </Sphere>

          <mesh castShadow position={[0, 1.5, -2]} scale={1}>
            <Image
              scale={[2, 2]}
              url={aiGeneratedImageURL}
              loaderOptions={{
                crossOrigin: "anonymous"
              }}
            />
          </mesh>
          <pointLight position={[0, 1.5, -0]} intensity="0.5" />

          <Float>
            <Model2 fileUrl2={aiGeneratedImageURL} position={[0, -0.28, 0]} />
          </Float>

          <group scale={[1, 1, 1]} position={[0, 1.6, -0]} rotation={[0, 0, 0]}>
            <Wall Background={BackgroundRef.current} />
          </group>

          <OrbitControls
            autoRotate="false"
            autoRotateSpeed={0}
            minPolarAngle={0}
            maxPolarAngle={Math.PI / 2}
            maxDistance={8}
            target={[0, 1, 0]}
          />
          <Environment preset="city" />
        </XR>
      </Canvas>
    </>
  );
}

function Wall(props) {
  const { scene, materials } = useGLTF("/bgscene2.glb");
  useLayoutEffect(() => {
    scene.traverse(
      (obj) => obj.isMesh && (obj.receiveShadow = obj.castShadow = true)
    );
    applyProps(materials.Material, {
      color: props.Background
    });
  });
  return (
    <primitive
      object={scene}
      {...props}
      rotation={[0, Math.PI / 2, 0]}
      position={[0, 0, 0]}
    />
  );
}

export function Model2(props) {
  const { nodes, materials } = useGLTF("/newt-3.glb");
  const [design] = useTexture([
    props.fileUrl2 ||
      "https://spestect.sirv.com/img-FPV8eXIW9ESuF89esu67NxLA.jpg"
  ]);

  if (nodes.mesh_0.material) {
    nodes.mesh_0.material.color.set(props.color || "white");
  }

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_0.geometry}
        material={nodes.mesh_0.material}
      >
        <Decal
          position={[0, 1.34, 0]}
          rotation={[0, 0, 0]}
          scale={[0.23, 0.23, 0.2]}
          map={design}
          roughness={1}
          map-anisotropy={16}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("/newt-3.glb");
