import { useAiImageContext } from "./AiImageContext";

const PredefinedImages = ({ images }) => {
  const { setAiGeneratedImageURL } = useAiImageContext();

  const handleImageClick = (imageUrl) => {
    setAiGeneratedImageURL(imageUrl); // Update the aiGeneratedImageURL directly
  };

  return (
    <div className="predefined-images">
      {images.map((imageUrl, index) => (
        <img
          key={index}
          src={imageUrl}
          onClick={() => handleImageClick(imageUrl)}
          alt={`Predefined image ${index}`}
          style={{ cursor: "pointer" }} // Adjust styles as needed
        />
      ))}
    </div>
  );
};

export default PredefinedImages;
