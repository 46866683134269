import { createContext, useContext, useState } from "react";

const AiImageContext = createContext();

export const useAiImageContext = () => useContext(AiImageContext);

export const AiImageProvider = ({ children }) => {
  const [aiGeneratedImageURL, setAiGeneratedImageURL] = useState(
    "https://spestect.sirv.com/img-FPV8eXIW9ESuF89esu67NxLA.jpg"
  );

  return (
    <AiImageContext.Provider
      value={{ aiGeneratedImageURL, setAiGeneratedImageURL }}
    >
      {children}
    </AiImageContext.Provider>
  );
};
