import React, { useState, Suspense } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import Scene2 from "./scene";
import { Loader } from "@react-three/drei";
import { useAiImageContext } from "./AiImageContext";
import PredefinedImages from "./PredefinedImages";
import ReactModal from "react-modal";

ReactModal.setAppElement("#root"); // This line is important for accessibility reasons.

const FETCH_VARIANTS = gql`
  query fetchVariants($productId: ID!) {
    product(id: $productId) {
      variants(first: 10) {
        edges {
          node {
            id
            title
          }
        }
      }
    }
  }
`;

const CREATE_CHECKOUT = gql`
  mutation createCheckout($lineItems: [CheckoutLineItemInput!]!) {
    checkoutCreate(input: { lineItems: $lineItems }) {
      checkout {
        id
        webUrl
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

function App() {
  const { aiGeneratedImageURL } = useAiImageContext();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const numericalProductId = 8303978611010;
  const base64EncodedProductId = `gid://shopify/Product/${numericalProductId}`;
  const base64ProductId = btoa(base64EncodedProductId);

  const [selectedVariant, setSelectedVariant] = useState("");

  const { loading: queryLoading, error: queryError, data } = useQuery(
    FETCH_VARIANTS,
    {
      variables: { productId: base64ProductId }
    }
  );

  const [
    createCheckout,
    { data: mutationData, loading: mutationLoading, error: mutationError }
  ] = useMutation(CREATE_CHECKOUT);

  const handleVariantChange = (event) => {
    setSelectedVariant(event.target.value);
  };

  const handleCheckout = async () => {
    const lineItems = [
      {
        variantId: selectedVariant,
        quantity: 1,
        customAttributes: [
          {
            key: "ai_design",
            value: aiGeneratedImageURL
          }
        ]
      }
    ];

    try {
      const { data } = await createCheckout({
        variables: { lineItems }
      });
      const checkoutUrl = data.checkoutCreate.checkout.webUrl;
      // Redirect the user to the Shopify checkout page
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error("Error creating checkout:", error);
    }
  };

  if (queryLoading) return <p>Loading...</p>;
  if (queryError) return <p>Error: {queryError.message}</p>;

  const variants = data.product.variants.edges;

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const customStyles = {
    contentContainer: {
      position: "relative",
      height: "100%"
    },
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      display: "flex", // NEW: Make this a flex container.
      flexDirection: "column", // NEW: Lay out children vertically.
      alignItems: "center", // NEW: Center children horizontally.
      maxWidth: "600px",
      maxHeight: "500px",
      width: "80%",
      padding: "0px",
      borderRadius: "10px",
      zIndex: "1000",
      boxSizing: "border-box",
      overflow: "auto" // Allow scrolling if the content is too tall.
    },
    overlay: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: "999"
    },
    closeButton: {
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      fontSize: "2rem", // Adjust as necessary
      color: "#000",
      zIndex: "1002" // Adjust as necessary
    },
    scrollableContent: {
      // New style
      overflowY: "auto", // Only allow vertical scrolling
      maxHeight: "calc(100% - 40px)",
      margin: "0",
      padding: "20px" // Adjust as necessary
    }
  };
  const predefinedImages = [
    "https://spestect.sirv.com/img-FPV8eXIW9ESuF89esu67NxLA.jpg",
    "https://spestect.sirv.com/20230516221052_abcd1234%20(1).jpg",
    "https://spestect.sirv.com/DALL%C2%B7E%202023-05-16%2022.59.20%20-%20Pixar%20style%203D%20render%20of%20a%20fox%20wearing%20a%20tophat%20and%20bowtie%2C%204k%2C%20high%20resolution%2C%20trending%20in%20artstation.jpg",
    "https://spestect.sirv.com/DALL%C2%B7E%202023-05-16%2023.07.53%20-%20alien%20playing%20fender%20jazz%20bass%20in%20the%20space%20time%20continuum%20pixar%20style%203d%20render%20.jpg",
    "https://spestect.sirv.com/img-XDQxgaUQzfiDl9Bt1kiG5D2w.jpg",
    "https://spestect.sirv.com/DALL%C2%B7E%202023-04-30%2019.58.38%20-%20Photograph%20of%20a%20polar%20bear%20wearing%20sunglasses%20surfing%20on%20a%20surfboard%20in%20a%20wave%20of%20custard%20.jpg"
  ];
  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <PredefinedImages images={predefinedImages} />
      </Suspense>
      <div className="scene-container">
        <Suspense fallback={<Loader />}>
          <Scene2 />
        </Suspense>
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div style={customStyles.contentContainer}>
          <button onClick={closeModal} style={customStyles.closeButton}>
            &times;
          </button>{" "}
          {/* Use the &times; HTML entity */}
          <div style={customStyles.scrollableContent}>
            <img
              src="./productT.jpg"
              alt="Description of the image"
              style={{
                width: "100%",
                maxWidth: "300px", // Make sure the image is not wider than the modal.
                height: "auto", // Keep the original aspect ratio.
                display: "block",
                margin: "0 auto", // Centers the block level image

                padding: "0px", // To remove any unwanted space around the image.
                marginBottom: "20px" // Add some space below the image.
              }}
            />
            <p style={{ fontFamily: "Archivo, sans-serif" }}>
              <strong>Description</strong>
              <br />
              <br />
              This heavyweight Unisex cotton t-shirt manufactured by Gildan is a
              durable staple product with a classic fit.
              <br />
              <ul>
                <li>Seamless double-needle collar</li>
                <li>Double-needle sleeve and bottom hems</li>
                <li>100% combed and ring-spun cotton</li>
                <li>Taped neck and shoulders for durability</li>
              </ul>
              <strong>Print information</strong>
              <br />
              <br />
              Your design is printed directly onto the garment. The 3D model is
              a digital representation and the actual T-Shirt may vary in
              appearance.
            </p>
          </div>
        </div>
      </ReactModal>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          backgroundColor: "rgba(255, 255, 255, 0)",
          WebkitBackdropFilter: "blur(20px)",
          backdropFilter: "blur(20px)",
          borderRadius: "8px",
          padding: "10px",
          margin: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          boxShadow: "0 0 5px rgba(0,0,0,0.3)",
          minWidth: "320px"
        }}
      >
        <div
          style={{
            display: "flex",
            marginBottom: "10px"
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              marginRight: "0px",
              fontFamily: "Archivo Black, sans-serif",
              color: "white"
            }}
          >
            Custom AI T-Print
          </span>
          <span
            style={{
              position: "absolute",
              right: 10,
              fontWeight: "bold",
              fontSize: "20px",
              fontFamily: "Archivo Black, sans-serif",
              color: "white"
            }}
          >
            &pound;24.99
          </span>
        </div>
        <div
          style={{
            display: "flex",
            // This will push your elements to the edges of the div.
            alignItems: "center" // This will vertically center your elements, if needed.
          }}
        >
          <span
            style={{
              fontWeight: "regular",
              fontSize: "14px",
              fontFamily: "Archivo, sans-serif",
              color: "white",
              marginBottom: "10px",
              textDecoration: "underline",
              cursor: "pointer",
              marginRight: "145px"
            }}
          >
            <a onClick={openModal}>View details</a>
          </span>
          <span
            style={{
              position: "absolute",
              right: 10,
              fontWeight: "regular",
              fontSize: "14px",
              fontFamily: "Archivo, sans-serif",
              color: "white",
              marginBottom: "10px"
            }}
          >
            Free UK delivery{" "}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginBottom: "10px"
          }}
        >
          <select
            value={selectedVariant}
            onChange={handleVariantChange}
            style={{
              padding: "10px",
              marginRight: "10px",
              fontSize: "16px",
              borderRadius: "80px",
              border: "none",
              boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
              color: "#333"
            }}
          >
            <option value="" disabled>
              Select Size
            </option>
            {variants.map((variant) => (
              <option key={variant.node.id} value={variant.node.id}>
                {variant.node.title}
              </option>
            ))}
          </select>
          <button
            onClick={handleCheckout}
            disabled={!selectedVariant}
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              borderRadius: "80px",
              border: "none",
              backgroundColor: "#007bff",
              color: "#fff",
              cursor: "pointer",
              boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)"
            }}
          >
            Buy Now
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
